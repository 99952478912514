<template>
  <div :class="containerClass">
    <h1
      :class="h1Class"
      data-custom-brand>
      {{ cleanTitle }}
    </h1>
  </div>
</template>

<script>
  import { toRefs, computed } from 'vue';
  import UtilsURL from 'CommonUtils/url';

  export default {
    name: 'TitlePLP',
    props: {
      title: {
        required: true,
        type: String,
      },
      isSearchPage: {
        type: Boolean,
        default: false,
      },
      isUniforms: {
        type: Boolean,
        default: false,
      }
    },
    setup(props) {
      const { title, isSearchPage } = toRefs(props);

      const replaceSearchTermAnalytics = (term) => {
        if (term && term.indexOf('>') !== -1) return term.slice(0, term.indexOf('>')) + '"';
        return term;
      };

      const cleanTitle = computed(() => {
        const sanitizeUrl = UtilsURL.sanitize(replaceSearchTermAnalytics(title.value));
        if (isSearchPage.value) return title.value;
        return sanitizeUrl;
      });

      const h1Class = computed(() => {
        return {
          'plp_title_h1': true,
          'uniforms_product-list_title': props.isUniforms,
        }
      });

      const containerClass = computed(() => {
        return {
          'h1_seo_title_container': true,
          'uniforms_h1_ontainer': props.isUniforms,
        }
      })

      return {
        cleanTitle,
        h1Class,
        containerClass,
      };
    },
  };
</script>
